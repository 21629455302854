<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" lg="8" md="10" offset-lg="2" offset-md="1">
        <v-card class="text-start py-10" flat>
          <v-card-title>{{ $vuetify.lang.t('$vuetify.pages.privacyAndPolicy.title') }}</v-card-title>
          <v-card-text>
            {{ $vuetify.lang.t('$vuetify.pages.privacyAndPolicy.text') }}
          </v-card-text>
          <v-card-text class="text-center">
            <kurcc-back-to-top/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'KurccHomePagePrivacyPolicy',
  components: {
    KurccBackToTop: () => import('@/modules/app/components/KurccBackToTop')
  }
}
</script>
